/* .app {
  padding: 10px;
}

.form-input {
  margin: 10px 0px;
}

.form-input input,
.form-input textarea {
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid lightgray;
  padding: 0.375rem 0.75rem;
  outline: none;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;
}

.blog-post__display {
  border: 1px solid lightgrey;
  margin: 5px 0px;
  border-radius: 4px;
  padding: 10px;
} */

/* .ant-table-thead{ */
/* top: 0 !important */
/* } */

#oneMatchTable > div > div.ant-table-header > table > thead > tr:nth-child(1) > th:nth-child(7) {
  width: 100px;
}

#oneMatchTable
  > div
  > div.ant-table-header
  > table
  > thead
  > tr:nth-child(1)
  > th.ant-table-cell.ant-table-cell-scrollbar {
    display: none;
}
